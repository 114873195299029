import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserAuthContext } from '../context/UserAuth';
//import Loading from '../../views/Loading/Loading';
//import Loader from '../components/common/Loader';

const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const { isAuthenticated, isLoading } = useContext(UserAuthContext);

  return (
    <Route
      {...otherProps}
      render={props =>
        !isLoading ? (
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            // <Redirect to={otherProps.redirectTo ? otherProps.redirectTo : '/authentication/basic/login'} />
            <Redirect
            to={{
              pathname: otherProps.redirectTo ? otherProps.redirectTo : '/auth/login',
              state: { from: props.location }
            }}
          />

          )
        ) : (
          // <Loader animation="grow" variant="primary" />
          <span>loading...</span>
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default PrivateRoute;
