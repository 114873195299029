import React from 'react';

const JamTrackPreviewContext = React.createContext(null)

export const JamTrackPreviewProvider = ({ children}) => {
    
    const [currentPlayTrackId, setCurrentPlayTrackId] = React.useState(null)
  
    return(
      <JamTrackPreviewContext.Provider value={ {currentPlayTrackId, setCurrentPlayTrackId} }>
        {children}
      </JamTrackPreviewContext.Provider>
    )
  }

export const useJamTrackPreview = () => React.useContext(JamTrackPreviewContext)
