export const version = '0.0.1';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'transparent',
};
export const sessionPrivacyMap = {
  public: 1,
  private_invite: 2,
  private_approve: 3
};
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings, sessionPrivacyMap };
