import React, { Fragment, useState } from 'react';
import { Row, Col } from 'reactstrap';
import JKJamTrackTrack from './JKJamTrackTrack';
import PropTypes from 'prop-types';
import { useResponsive } from '@farfetch/react-context-responsive';

const JKJamTrackPreview = ({ jamTrack, showMasterDuration = false, columns= true, initialJamTracksShowCount = 6 }) => {
  const [expanded, setExpanded] = useState(false);
  const { greaterThan } = useResponsive();

  const toggleMoreTracks = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <>
      {columns && greaterThan.sm ? (
        <Row>
          {jamTrack.tracks.filter(t => t.track_type !== 'Click').map((track, index) => (
            <Fragment key={track.id} >
              <Col className={index + 1 > initialJamTracksShowCount && !expanded ? 'd-none' : null}>
                <JKJamTrackTrack track={track} duration={track.track_type === 'Master' ? jamTrack.duration : null} />
              </Col>
              {(index + 1) % 2 === 0 && <div className="w-100" />}
              {(index + 1) === jamTrack.tracks.length && (index + 1) % 2 !== 0 && <div className="col" />}
            </Fragment>
          ))}
        </Row>
      ) : (
        <Row>
          {jamTrack.tracks.filter(t => t.track_type !== 'Click').map((track, index) => (
            <Col key={track.id} xs={12} className={index + 1 > initialJamTracksShowCount && !expanded ? 'd-none mb-3' : 'mb-3'}>
              <JKJamTrackTrack track={track} duration={track.track_type === 'Master' ? jamTrack.duration : null} />
            </Col>
          ))}
        </Row>
      )}

      {jamTrack.tracks.length > initialJamTracksShowCount && (
        <Row>
          <Col>
            <a href="#" onClick={toggleMoreTracks}>{expanded ? 'Show fewer tracks' : 'Show all tracks'}</a>
          </Col>
        </Row>
      )}
    </>
  );
};

JKJamTrackPreview.propTypes = {
  jamTrack: PropTypes.object.isRequired
};

export default JKJamTrackPreview;
