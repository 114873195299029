import ReactGA from "react-ga4";

var initialized = false;

// Noticed that ReactGA.initialize logs a page view,
// so the 1st logPageView will end up double-counting the initial path landed on
var ignoredFirstPageView = false;

export const initGA = () => {
    try {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
            ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
            initialized = true;
        } else {
            initialized = false;
            console.log("no GA ID found");
        }
    }
    catch (error) {
        console.log("Error initializing GA", error);
    }
};

export const logPageView = (path) => {
    if(initialized) {
        if(ignoredFirstPageView) {
            try {
                ReactGA.send({hitType: "pageview", page: path});
            }
            catch(error) {
                console.log("Error logging page view", error);
            }
        }
        else {
            ignoredFirstPageView = true;
        }
    }
};