import React, { useEffect } from 'react';
import Layout from './layouts/JKLayout';
import AnalyticsTracker from "./components/utilities/AnalyticsTracker";

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';


const App = () => {

    return (
      <>
          <AnalyticsTracker />
          <Layout />
      </>

  );
};

export default App;
