import React, { useContext, useEffect, useRef, useState } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PrivateRoute from '../../helpers/privateRoute';

import NavbarTop from '../navbar/JKNavbarTop';
import NavbarVertical from '../navbar/JKNavbarVertical';
import Footer from '../footer/JKFooter';

import { toast } from 'react-toastify';

import AppContext from '../../context/Context';
import { getPageName } from '../../helpers/utils';

import useScript from '../../hooks/useScript';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage } from '../../store/features/textMessagesSlice';
import { add as addNotification } from '../../store/features/notificationSlice';
import { useLobbyChat } from '../sessions/JKLobbyChatContext';

import { useAuth } from '../../context/UserAuth';
import { truncate } from '../../helpers/utils';

//import HomePage from '../page/JKHomePage';

import JKPeopleFilter from '../page/JKPeopleFilter';
import JKMyFriends from '../page/JKMyFriends';
import JKNotifications from '../page/JKNotifications';
import JKMessageModal from '../profile/JKMessageModal';
import JKAppLaunch from '../page/JKAppLaunch';

import JKMusicSessions from '../page/JKMusicSessions';
import JKNewMusicSession from '../page/JKNewMusicSession';
import JKMusicSessionsLobby from '../page/JKMusicSessionsLobby';
import JKMusicSessionsHistory from '../page/JKMusicSessionsHistory';

import JKEditProfile from '../page/JKEditProfile';
import JKEditAccount from '../page/JKEditAccount';
import JKAccountSubscription from '../page/JKAccountSubscription';
import JKPaymentHistory from '../page/JKPaymentHistory';
import JKAccountPreferences from '../page/JKAccountPreferences';

import JKAffiliateProgram from '../affiliate/JKAffiliateProgram';
import JKAffiliatePayee from '../affiliate/JKAffiliatePayee';
import JKAffiliateLinks from '../affiliate/JKAffiliateLinks';
import JKAffiliateSignups from '../affiliate/JKAffiliateSignups';
import JKAffiliateEarnings from '../affiliate/JKAffiliateEarnings';
import JKAffiliateAgreement from '../affiliate/JKAffiliateAgreement';

import JKJamTracksFilter from '../jamtracks/JKJamTracksFilter';
import JKShoppingCart from '../shopping-cart/JKShoppingCart';
import JKCheckout from '../shopping-cart/JKCheckout';
import JKCheckoutSuccess from '../shopping-cart/JKCheckoutSuccess';
import JKMyJamTracks from '../jamtracks/JKMyJamTracks';
import JKJamTrackShow from '../jamtracks/JKJamTrackShow';


//import loadable from '@loadable/component';
//const DashboardRoutes = loadable(() => import('../../layouts/JKDashboardRoutes'));
//const PublicRoutes = loadable(() => import('../../layouts/JKPublicRoutes'))

const Msg = ({ closeToast, toastProps, title }) => (
  <div>
    <a href="#">{title}</a>
  </div>
);

function JKDashboardMain() {

  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);
  const isKanban = getPageName('kanban');

  const { isAuthenticated, currentUser, setCurrentUser, logout } = useAuth();

  const scriptLoaded = useRef(false);

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageUser, setMessageUser] = useState(null);

  const {
    setMessages: setChatMessages,
    lobbyChatOffset,
    setLobbyChatOffset,
    fetchLobbyMessages,
    lobbyChatLimit,
    goToBottom
  } = useLobbyChat();

  // useEffect(() => {
  //   DashboardRoutes.preload();
  //   PublicRoutes.preload();
  // }, []);

  const [visibilityState, setVisibilityState] = useState('visible');

  useEffect(() => {
    setVisibilityState(document.visibilityState);
    // if(currentUser){
    //   postInteraction(document.visibilityState);
    // }
  }, [document.visibilityState]);

  const dispatch = useDispatch();

  // const postInteraction = visibilityState => {
  //   const options = {
  //     client: INTERACTION_CLIENT,
  //     screen: INTERACTION_SCREEN,
  //     user_id: currentUser.id,
  //   };
  //   if (visibilityState === 'visible') {
  //     options.action = INTERACTION_PAGE_ENTER;
  //   } else {
  //     options.action = INTERACTION_PAGE_EXIT;
  //   }

  //   postUserAppInteraction(options)
  //     .then(response => {
  //       console.log('User app interactions updated', response);
  //     })
  //     .catch(error => {
  //       console.log('Error updating user app interactions', error);
  //     });
  // };

  const initJKScripts = () => {

    if (scriptLoaded.current) {
      return;
    }


    const app = window.JK.JamKazam();

    const jamServer = new window.JK.JamServer(app, function (event_type) {
      console.log('EVENT_TYPE', event_type);
    });
    jamServer.initialize();

    window.JK.initJamClient(app);
    const clientInit = new window.JK.ClientInit();
    clientInit.init();

    window.JK.JamServer.connect() // singleton here defined in JamServer.js
      .done(function () {
        console.log('Jamserver connected...');
        //_initAfterConnect(true);
      })
      .fail(function () {
        console.log('Jamserver connection error...');
        //_initAfterConnect(false);
      });

    registerTextMessageCallback();
    registerFriendRequest();
    registerFriendRequestAccepted();
    registerChatMessageCallback();
    registerSubscriptionCallback();

    scriptLoaded.current = true;
  };

  const registerTextMessageCallback = () => {
    window.JK.JamServer.registerMessageCallback(window.JK.MessageType.TEXT_MESSAGE, function (header, payload) {
      console.log('registerTextMessageCallback payload', payload);
      //console.log('registerTextMessageCallback header', header);
      const msg = {
        id: payload.text_message_id,
        message: payload.msg,
        senderId: payload.sender_id,
        senderName: payload.sender_name,
        receiverId: window.currentUser.id,
        receiverName: window.currentUser.first_name,
        createdAt: payload.created_at,
        sent: true
      };

      dispatch(addMessage(msg));

      //toast(<Msg title={`${msg.senderName} has sent a new message`} />)
      toast.info(`${msg.senderName} has sent a new message`, {
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        onClick: () => {
          setMessageUser({ id: msg.senderId, name: msg.senderName });
          setShowMessageModal(true);
        }
      });

      handleNotification(payload, header.type);
    });
  };

  const registerChatMessageCallback = () => {
    window.JK.JamServer.registerMessageCallback(window.JK.MessageType.CHAT_MESSAGE, function (header, payload) {
      console.log('registerChatMessageCallback ' + JSON.stringify(payload));
      if (payload !== undefined && payload.sender_id !== window.currentUser.id) {
        if (visibilityState === 'hidden' && Notification.permission === 'granted') {
          try {
            const notification = new Notification('JamKazam Lobby Message', {
              body: `${payload.sender_name}: ${truncate(payload.msg, 100)}`
              //icon: `${process.env.REACT_APP_CLIENT_BASE_URL}/assets/img/jamkazam-logo.png`
            });
            notification.onclick = function (event) {
              event.preventDefault(); // prevent the browser from focusing the Notification's tab
              window.focus();
              event.target.close();
            };
          } catch (err) {
            console.log('Error when showing notification', err);
          }
        }
        setChatMessages([]);
        fetchLobbyMessages({
          start: 0,
          limit: lobbyChatOffset === 0 ? lobbyChatLimit : lobbyChatOffset * lobbyChatLimit
        });
        //goToBottom();
      }
    });
  };

  const registerFriendRequest = () => {
    window.JK.JamServer.registerMessageCallback(window.JK.MessageType.FRIEND_REQUEST, function (header, payload) {
      handleNotification(payload, header.type);
    });
  };

  const registerFriendRequestAccepted = () => {
    window.JK.JamServer.registerMessageCallback(window.JK.MessageType.FRIEND_REQUEST_ACCEPTED, function (
      header,
      payload
    ) {
      console.log('registerFriendRequestAccepted payload', payload);
      console.log('registerFriendRequestAccepted header', header);
      handleNotification(payload, header.type);
    });
  };

  const registerSubscriptionCallback = () => {
    window.JK.JamServer.registerMessageCallback(window.JK.MessageType.SUBSCRIPTION_MESSAGE, function (header, payload) {
      console.log('registerSubscriptionCallback payload', payload);
      console.log('registerSubscriptionCallback header', header);
      handleSubscriptionMessage(payload);
    });
  };

  const handleNotification = (payload, type) => {
    console.log('handleNotification', payload, type);
    const notification = {
      description: type,
      notification_id: payload.notification_id,
      session_id: payload.session_id,
      friend_request_id: payload.friend_request_id,
      formatted_msg: payload.msg,
      text_message_id: payload.text_message_id,
      message: payload.msg,
      source_user_id: payload.sender_id,
      source_user: {
        name: payload.sender_name
      },
      created_at: payload.created_at
    };

    try {
      dispatch(addNotification(notification));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubscriptionMessage = payload => {

  }

  useScript(`${process.env.REACT_APP_CLIENT_BASE_URL}/client_scripts`, initJKScripts);

  return (
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      {isVertical && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}

      <div className="content">
        <NavbarTop />
        <Switch>
          {/* <PrivateRoute path="/" exact component={HomePage} /> */}
          <PrivateRoute path="/" exact component={JKEditProfile} />
          <PrivateRoute path="/friends/my" component={JKMyFriends} />
          <PrivateRoute path="/friends" component={JKPeopleFilter} />
          <PrivateRoute path="/sessions/new" component={JKNewMusicSession} />
          <PrivateRoute path="/sessions/lobby" component={JKMusicSessionsLobby} />
          <PrivateRoute path="/sessions/history" component={JKMusicSessionsHistory} />
          <PrivateRoute path="/sessions" component={JKMusicSessions} />
          <PrivateRoute path="/notifications" component={JKNotifications} />
          <PrivateRoute path="/profile" component={JKEditProfile} />
          <PrivateRoute path="/account/identity" component={JKEditAccount} />
          <PrivateRoute path="/account/subscription" component={JKAccountSubscription} />
          <PrivateRoute path="/account/payments" component={JKPaymentHistory} />
          <PrivateRoute path="/account/preferences" component={JKAccountPreferences} />
          <PrivateRoute path="/affiliate/program" component={JKAffiliateProgram} />
          <PrivateRoute path="/affiliate/payee" component={JKAffiliatePayee} />
          <PrivateRoute path="/affiliate/links" component={JKAffiliateLinks} />
          <PrivateRoute path="/affiliate/signups" component={JKAffiliateSignups} />
          <PrivateRoute path="/affiliate/earnings" component={JKAffiliateEarnings} />
          <PrivateRoute path="/affiliate/agreement" component={JKAffiliateAgreement} />
          <PrivateRoute path="/jamtracks" component={JKJamTracksFilter} />
          <PrivateRoute path="/jamtrack/:id" component={JKJamTrackShow} />
          <PrivateRoute path="/jamtracks" component={JKJamTracksFilter} />
          <PrivateRoute path="/my-jamtracks" component={JKMyJamTracks} />
          <PrivateRoute path="/shopping-cart" component={JKShoppingCart} />
          <PrivateRoute path="/checkout/success" component={JKCheckoutSuccess} />
          <PrivateRoute path="/checkout" component={JKCheckout} />
          <PrivateRoute path="/applaunch" component={JKAppLaunch} />
          {/*Redirect*/}
          <Redirect to="/errors/404" />
        </Switch>
        {!isKanban && <Footer />}
      </div>
      {messageUser && <JKMessageModal show={showMessageModal} setShow={setShowMessageModal} user={messageUser} />}

      {/* <SidePanelModal path={location.pathname} /> */}
    </div>
  );
}

export default JKDashboardMain;
