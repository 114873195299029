import { getShoppingCart, addJamtrackToShoppingCart, removeShoppingCart } from "../helpers/rest"
import { useState, useEffect, useMemo, useCallback } from "react";

export const useShoppingCart = () => {
  const [loading, setLoading] = useState(false);
  const [shoppingCart, setShoppingCart] = useState([]);
  const [error, setError] = useState(null);
  const TAX_RATE = 0.1;

  const cartTotal = useMemo(() => {
    //calculate total price
    if(shoppingCart.length === 0){
      return 0.00;
    }
    const totalPrice = shoppingCart.reduce((acc, item) => acc + parseFloat(item.product_info.real_price), 0.00);
    return totalPrice;
  }, [shoppingCart]);

  const getCartItems = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await getShoppingCart();
      const data = await resp.json();
      setShoppingCart(data);
    } catch (error) {
      console.error(error);
      setError(error);
    }finally{
      setLoading(false);
    }
  }, []);
 
  const addCartItem = useCallback(async (options) => {
    try {
      const resp = await addJamtrackToShoppingCart(options);
      const data = await resp.json();
      setShoppingCart([...shoppingCart, data]);
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }, [shoppingCart]);

  // const removeCartItem = useCallback(async (id) => {
  //   try {
  //     await removeShoppingCart({id});
  //     setShoppingCart(shoppingCart.filter(item => item.id !== id));
  //     return true;
  //   } catch (error) {
  //     console.error(error);
  //     return false;
  //   }
  
  // }, []);

  const removeCartItem = async (id) => {
    try {
      await removeShoppingCart({id});
      setShoppingCart(shoppingCart.filter(item => item.id !== id));
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  
  }

  const hasOnlyFreeItemsInShoppingCart = useCallback(() => {
    return shoppingCart.length === 0 || shoppingCart.every(item => item.product_info.free);
  }, [shoppingCart]);

  return{
    shoppingCart, error, loading, getCartItems, removeCartItem, addCartItem, cartTotal, hasOnlyFreeItemsInShoppingCart
  }
}