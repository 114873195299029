// Desc: This file contains the custom hook for adding jamtracks to the shopping cart
import { useAuth } from '../context/UserAuth';
import { useShoppingCart } from './useShoppingCart';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { placeOrder, updateUser } from '../helpers/rest';

const useJamTrackShopping = () => {
  const { currentUser } = useAuth();
  const { addCartItem, hasOnlyFreeItemsInShoppingCart } = useShoppingCart();
  const { t } = useTranslation('jamtracks');
  const history = useHistory();

  // forceReload means to navigate 'forcefully' using window.location.href, because 
  // most likely coming from a landing page, which is not a part of the SPA
  const addToCart = async (jamTrack, forceReload = false) => {
    if (!currentUser) {
      return;
    }
    const options = {
      id: jamTrack.id,
      variant: 'full'
    };

    try {
      const resp = await addCartItem(options);
      if (resp.fast_reedem) { //if this is a free jamtrack
        //get shopping cart items and see if all are free
        if (!hasOnlyFreeItemsInShoppingCart()) {
          history.push('/jamtracks');
        } else {
          const purchadeResp = await placeOrder();
          if (purchadeResp.ok) {
            const userResp = await updateUser(currentUser.id);
            if (userResp.ok) {
              const checkoutPath = '/checkout/success?free=yes&jamtrackId=' + jamTrack.id;
              if (forceReload) {
                window.location.href = checkoutPath;
              }
              else {
                history.push(checkoutPath);
              }
            }
          }
        }
      } else { //if this is a paid jamtrack
        toast.success(t('search.list.add_success_alert'));
        const shoppingCartPath = '/shopping-cart';
        if (forceReload) {
          window.location.href = shoppingCartPath;
        }
        else {
          history.push(shoppingCartPath);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(t('search.list.add_error_alert'));
    }
  };

  return {
    addToCart,
  };
};

export default useJamTrackShopping;
