import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const playIcon = '/img/icons/play-icon.svg';
const pauseIcon = '/img/icons/pause-icon.svg';
const downloadIcon = '/img/icons/download-icon.svg';

const JKAudioPlayer = ({ audioUrl, onDownloadClick }) => {

  const audioPlayer = useRef(null);

  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    if (!audioUrl) return;
    console.log('audioUrl: ' + audioUrl)

    const audio = new Audio(audioUrl);
    setAudio(audio);

  }, [audioUrl]);

  useEffect(() => {
    if (!audio || !audioPlayer) return;
    if (audio) {
      stopAudio();
    }
    const audioLoadedDataEventListener = audio.addEventListener("loadeddata", () => {
      audioPlayer.current.querySelector(".time .length").textContent = getTimeCodeFromNum(
        audio.duration
      );
      audio.volume = .75;
    });

    const audioPercentageCheckInterval = setInterval(() => {
      const progressBar = audioPlayer.current.querySelector(".progress");
      progressBar.style.width = audio.currentTime / audio.duration * 100 + "%";
      audioPlayer.current.querySelector(".time .current").textContent = getTimeCodeFromNum(
        audio.currentTime
      );
    }, 500);

    //audio.play();

    return () => {
      if (audio) {
        stopAudio();
        audio.removeEventListener("loadeddata", audioLoadedDataEventListener);
        clearInterval(audioPercentageCheckInterval);
      }
    }
  }, [audio, audioPlayer]);

  const stopAudio = () => {
    if (!audio) return;
    audio.pause();
    audio.currentTime = 0;
    setIsPlaying(false);
  }

  const togglePlayPause = (e) => {
    if (!audio) return;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  }

  const onClickTimeline = (e) => {
    if (!audio) return;
    let timeToSeek = 0;
    try {
      let timelineWidth = window.getComputedStyle(audioPlayer.current.querySelector(".timeline")).width;
      //remove px from the string if it exists
      if (timelineWidth.includes('px')) {
        timelineWidth = timelineWidth.replace('px', '');
      }
      timeToSeek = Math.floor(e.nativeEvent.offsetX / parseInt(timelineWidth) * audio.duration);
    } catch (e) {
      console.error('Error seeking audio: ' + e)
      timeToSeek = 0;
    }
    audio.currentTime = timeToSeek;
  }

  const onVolumeSliderClick = (e) => {
    if (!audio) return;
    let volume = .75;
    try {
      let sliderWidth = window.getComputedStyle(audioPlayer.current.querySelector(".controls .volume-slider")).width;
      if (sliderWidth.includes('px')) {
        sliderWidth = sliderWidth.replace('px', '');
      }
      volume = parseFloat((e.nativeEvent.offsetX / parseInt(sliderWidth)).toFixed(2));
    } catch {
      console.error('Error changing volume')
      volume = .75;
    }
    audio.volume = volume;
    audioPlayer.current.querySelector(".controls .volume-percentage").style.width = volume * 100 + '%';
  }

  const onVolumeButtonClick = () => {
    if (!audio) return;
    audio.muted = !audio.muted;
    setIsMuted(audio.muted);
  }

  const getTimeCodeFromNum = (num) => {
    let seconds = parseInt(num);
    let minutes = parseInt(seconds / 60);
    seconds -= minutes * 60;
    const hours = parseInt(minutes / 60);
    minutes -= hours * 60;

    if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
    return `${String(hours).padStart(2, 0)}:${minutes}:${String(
      seconds % 60
    ).padStart(2, 0)}`;
  }

  return (
    audioUrl && audio && (
      <div className="audio-player" ref={audioPlayer}>

        <div className="controls">
          <div className="play-container">
            <button
              onClick={(e) => togglePlayPause(e)}
              className="play-pause-button"
            >
              {isPlaying ? (
                <img src={pauseIcon} alt="Pause" width="30" />
              ) : (
                <img src={playIcon} alt="Play" width="30" />
              )}
            </button>
          </div>
          <div className="time">
            <div className="current">0:00</div>
            <div className="divider">/</div>
            <div className="length"></div>
          </div>
          {/* <div className="name">Music Song</div> */}
          <div className="timeline" onClick={(e) => onClickTimeline(e)}>
            <div className="progress"></div>
          </div>
          <div className="volume-container">
            <div className="volume-button" onClick={(e) => onVolumeButtonClick(e)}>
              <div className="volume">
                {isMuted ? (
                  <FontAwesomeIcon icon="volume-off" width={30} height={30} />
                ) : (
                  <FontAwesomeIcon icon="volume-up" width={30} height={30} />
                )}
              </div>
            </div>

            <div className="volume-slider" onClick={onVolumeSliderClick}>
              <div className="volume-percentage"></div>
            </div>
          </div>
          <div className='download-container'>
            <button className='download-button' onClick={onDownloadClick}>
              <img src={downloadIcon} alt='download' width={30} />
            </button>
          </div>
        </div>
      </div>
    )
  );

}

JKAudioPlayer.propTypes = {
  audioUrl: PropTypes.string.isRequired,
  onDownloadClick: PropTypes.func.isRequired
}

export default JKAudioPlayer
