import React, {useEffect} from 'react';
import { Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './JKDashboardLayout';
import ErrorLayout from './ErrorLayout';
import BuildMeta from "./JKBuildMeta";

import loadable from '@loadable/component';
const AuthBasicLayout = loadable(() => import('./JKAuthBasicLayout'));
const PublicLayout = loadable(() => import('./JKPublicLayout'));

const Layout = () => {
  useEffect(() => {
    AuthBasicLayout.preload();
    PublicLayout.preload();

    //see if there is affiliate in query string and save it as cookie
    const urlParams = new URLSearchParams(window.location.search);
    const affiliate = urlParams.get('affiliate');

    const maxAge = 24 * 3600; // 1 day
    if (affiliate) {
      console.log(`affiliate found ${affiliate} ${process.env.REACT_APP_COOKIE_DOMAIN}`);
      document.cookie = `affiliate_visitor=${affiliate}; path=/; max-age=${maxAge}; domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
    }
  }, []);

  return (
      <>
      <Switch>
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/auth" component={AuthBasicLayout} />
        <Route path="/public" component={PublicLayout} />
        <Route component={DashboardLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_RIGHT} />
      <BuildMeta />
      </>
  );
};

export default Layout;
