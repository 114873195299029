// AnalyticsTracker.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { initGA, logPageView } from "../../helpers/analytics";

const AnalyticsTracker = () => {
    const location = useLocation();

    useEffect(() => {
        initGA();
    }, []);
    useEffect(() => {
        logPageView(location.pathname + location.search);
    }, [location]);

    return null; // it doesn't render anything
};

export default AnalyticsTracker;